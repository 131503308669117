import React from "react"
import { Link } from 'gatsby'
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from '../components/Layout'
import CTAStart from "../components/CTAStart"
import SEO from "../components/SEO"

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
}

const Process = (props) => {
    return (
        <Layout>
            <SEO title={"Process"} />
            <div className="rbs-section">
                <div className="rbs-box-a">
                    <div className="content">
                        <h1 className="title-xl mb-8">
                            Process
                        </h1>
                    </div>
                </div>
                <div className="rbs-bg bg-02"></div>
            </div>

            <div className="rbs-section">
                <div className="rbs-bg bg-02"></div>
                <div className="rbs-box-a">
                    <div className="content">
                        <h2 className="title-l mb-16">
                            Pre-Sprint
                        </h2>

                        <div className="grid-a">
                            <div>
                                <h3 className="title-s-b">Brand Strategy Workshop</h3>
                                <p className="body-l text-rbs-black-50 mb-4">We’ll start with a free strategy workshop to align our teams and set a stage for the brand sprint.</p>
                                <p className="body-s uppercase text-rbs-black-50">1HR VIDEO CALL</p>

                            </div>
                            <div>
                                <h3 className="title-s-b">Brand Brief</h3>
                                <p className="body-l text-rbs-black-50 mb-4">You’ll receive a documentation of the workshop findings which you can use to further develop your company.</p>
                                <p className="body-s uppercase text-rbs-black-50">AFTER 3–5 DAYS</p>

                            </div>

                        </div>
                        <Link to="/start" className="body-l link-arrow">Join the Free 1hr Workshop</Link>
                    </div>
                </div>
            </div>

            <div className="rbs-section">
                <div className="rbs-box-a">
                    <div className="content">
                        <h2 className="title-l mb-16">
                            Week 1
                        </h2>

                        <div className="grid-a">
                            <div>
                                <h3 className="title-s-b">Kick-off Meeting</h3>
                                <p className="body-l text-rbs-black-50 mb-4">We’ll start with a free strategy workshop to align our teams and set a stage for the brand sprint.</p>
                                <p className="body-s uppercase text-rbs-black-50">Monday morning</p>
                            </div>
                            <div>
                                <h3 className="title-s-b">
                                    Directions Review
                                </h3>
                                <p className="body-l text-rbs-black-50 mb-4">We’ll review moodboards and explorations for possible brand directions. We’ll decide upon a focus direction that is going to be developed further.</p>
                                <p className="body-s uppercase text-rbs-black-50">FRIDAY MORNING</p>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="rbs-bg bg-02"></div>

            </div>

            <div className="rbs-section">
                <div className="rbs-bg bg-02"></div>
                <div className="rbs-box-a">
                    <div className="content">
                        <h2 className="title-l mb-16">
                            Week 2
                        </h2>

                        <div className="grid-a">
                            <div>
                                <h3 className="title-s-b">Brand Focus Review</h3>
                                <p className="body-l text-rbs-black-50 mb-4">We’ll review the design work on focused brand as well as design concepts for various applications.</p>
                                <p className="body-s uppercase text-rbs-black-50">Tuesday morning</p>
                            </div>
                            <div>
                                <h3 className="title-s-b">
                                    Final Demo
                                </h3>
                                <p className="body-l text-rbs-black-50 mb-4">Your new brand is ready to be launched. You’ll get the brand manual, logo, applications etc.</p>
                                <p className="body-s uppercase text-rbs-black-50">FRIDAY MORNING</p>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="rbs-section">
                <div className="rbs-box-a">
                    <div className="content">
                        <h2 className="title-l mb-16">
                            Post-Sprint
                        </h2>

                        <div className="grid-a">
                            <div>
                                <h3 className="title-s-b">Brand Toolbox Hand-off</h3>
                                <p className="body-l text-rbs-black-50 mb-4">We’ll add final polish and document all the work carried out during the workshop. You’ll receive the brand manual and all the design files.</p>
                                <p className="body-s uppercase text-rbs-black-50">3–5 WORKDAYS AFTER THE SPRINT</p>

                            </div>
                            <div>
                                <h3 className="title-s-b">
                                    Support Call
                                </h3>
                                <p className="body-l text-rbs-black-50 mb-4">We’ll call you a few weeks after the sprint to see how you’re doing and whether everything is on track.</p>
                                <p className="body-s uppercase text-rbs-black-50">2–4 WEEKS AFTER THE SPRINT</p>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="rbs-bg bg-02"></div>

            </div>

            <div className="case-bg-02 p-16 pt-32 md:p-32">
                <div className="text-white case-grid">
                    <div>
                        <h3 className="title">Abacus</h3>
                        <p className="body-xl opacity-50">Redefining property management</p>
                    </div>
                    <div className="col-span-2">
                        <p className="body-l opacity-50">Abacus is on a mission to reinvent the commercial real estate management market. A fair amount of work in this conservative sector is still carried out using Excel spreadsheets other dated methods. Abacus will change that. We helped them build their very first visual identity programme, which has proved to be an invaluable asset for onboarding and attracting the trust of the large asset management funds.</p>
                    </div>
                </div>
                <div className="relative w-full mx-auto">
                    <Img imgStyle={{ objectFit: 'contain' }} fluid={props.data.case04.childImageSharp.fluid} />
                </div>

                <div className="text-center mt-16">
                    <Link to="/cases" className="text-white opacity-50 body-l link-arrow">View more cases</Link>
                </div>

            </div>

            <CTAStart />
        </Layout>
    )
}

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    case04: file(relativePath: { eq: "case-04.png" }) {
      ...fluidImage
    }
  }
`

export default Process;